<template>
  <div>
    <div class="row mb-1">
      <div class="col-md-12">
        <a-button :disabled="selectedIds.length > 0 ? false : true" class="btn btn-success float-right" :loading="onBtnLoading" @click.prevent="onAll"><i class="fa fa-check mr-1" aria-hidden="true"></i>On All</a-button>
        <a-button :disabled="selectedIds.length > 0 ? false : true" class="btn btn-danger float-right mr-2" :loading="offBtnLoading" @click.prevent="offAll"><i class="fa fa-remove mr-1" aria-hidden="true"></i>Off All</a-button>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <a-tooltip placement="top">
            <template slot="title"><span>Add Menu Setting</span></template>
            <a href="javascript: void(0);" class="btn btn-sm btn-success float-right mb-1" v-b-modal.add-update-menu-setting-modal @click="$bvModal.show('add-update-menu-setting-modal'), resetForm()">
              <i class="fa fa-plus"/> Add Menu Setting
            </a>
          </a-tooltip>
          <table class="table table-sm table-bordered" id="menu_setting_list">
            <thead>
            <tr>
              <th><input v-if="menu_settings.length > 0" type="checkbox" v-model="selectAll" @click="CheckedAll" /></th>
              <th>#</th>
              <th>Status</th>
              <th>Hub</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(menu_setting, index) in menu_settings" :key="index">
              <td><input type="checkbox" v-model="selectedIds" :value="menu_setting.id" @change="selectOne"></td>
              <td>{{ index + 1 }}</td>
              <td>
                <a-tag color="#87d068"  v-if="menu_setting.status == 1">
                  ON
                </a-tag>
                <a-tag color="#f50" v-else>
                  OFF
                </a-tag>
              </td>
              <td>{{ menu_setting.hub ? menu_setting.hub.name : '' }}</td>
              <td>
                <a class="btn btn-sm btn-primary mr-2" href="javascript: void(0);" @click.prevent="edit(menu_setting.id), $bvModal.show('add-update-menu-setting-modal')"><i class="fe fe-edit mr-1"></i>Update</a>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="menu_settings.length === 0 && !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
      <add-update-menu-setting
        :menu_setting="menu_setting"
        :btnEdit="btnEdit"
        @getMenuSettings="getMenuSettings"
        @resetForm="resetForm"
        ref="addUpdateMenuSetting">
      </add-update-menu-setting>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import addUpdateMenuSetting from '@/views/menuSetting/modal/addUpdateMenuSetting'
import $ from 'jquery'

export default {
  name: 'List',
  components: {
    Empty,
    addUpdateMenuSetting,
  },
  data() {
    return {
      hubs: [],
      menu_settings: [],
      menu_setting: {},
      selectedIds: [],
      selectAll: false,
      onBtnLoading: false,
      offBtnLoading: false,
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getMenuSettings()
  },
  methods: {
    getMenuSettings() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/menu-settings')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.menu_settings = response.data.menu_settings
          setTimeout(function () {
            $('#menu_setting_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
    edit(id) {
      this.menu_setting = {}
      this.btnEdit = true
      apiClient.get('api/menu-settings/' + id + '/edit').then(response => {
        if (!response.data.error) {
          this.menu_setting = response.data.menu_setting
        }
      })
    },
    onDelete(Id) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/menu-settings/' + Id).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#menu_setting_list').DataTable().destroy()
              this.getMenuSettings()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    CheckedAll() {
      this.selectedIds = []
      if (!this.selectAll) {
        const menuSettings = this.menu_settings
        for (const i in menuSettings) {
          this.selectedIds.push(menuSettings[i].id)
        }
      }
    },
    selectOne() {
      this.selectAll = false
      if (this.selectedIds.length === this.menu_settings.length) {
        this.selectAll = true
      }
    },
    onAll() {
      if (this.selectedIds.length > 0) {
        this.onBtnLoading = true
        apiClient.post('api/menu-setting/onAll', { menuSettingIds: this.selectedIds })
          .then(response => {
            this.onBtnLoading = false
            this.selectAll = false
            this.selectedIds = []
            this.getMenuSettings()
            this.$notification.success({
              message: response.data.message,
            })
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.$notification.error({
          message: 'Please select any checkbox from table',
        })
      }
    },
    offAll() {
      if (this.selectedIds.length > 0) {
        this.offBtnLoading = true
        apiClient.post('api/menu-setting/offAll', { menuSettingIds: this.selectedIds })
          .then(response => {
            this.offBtnLoading = false
            this.selectAll = false
            this.selectedIds = []
            this.getMenuSettings()
            this.$notification.success({
              message: response.data.message,
            })
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.$notification.error({
          message: 'Please select any checkbox from table',
        })
      }
    },
    resetForm() {
      this.btnEdit = false
      this.menu_setting = {}
    },
  },
}
</script>

<style scoped>

</style>
